import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { Link } from "gatsby";
import { NewsletterMini } from "modules/Newsletter/Newsletter";
import BlockContent from "components/block-content";
import OutcomeControls from "components/OutcomeControls/OutcomeControls";
import WorkVideoSummary from "components/WorkVideoSummary/WorkVideoSummary";
import { PadSection, SectionMargin } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import ArrowIcon from "./assets/arrow.svg";
import { media } from "utils/mediaQuery";

const Outer = styled.div`
  overflow: hidden;
`
const Wrapper = styled(SectionMargin)`
  position: relative;
  margin-top: 0;
  ${media.medium`
    margin-top: 50px;
    margin-bottom: 50px;
  `}
`;

const MainCol = styled.div`
  grid-column: 1 / span 6;
  border-radius: 5px;
  box-sizing: border-box;

  ${media.medium`
    border: 1.5px solid white;
    grid-column: 2 / span 10;
    padding: 5vw 0;
  `}
`

const SlideImage = styled.img`
  position: relative;
  width: 100%;
`

const Title = styled.h2`
  ${fontstack.default}
  font-weight: 400;
  margin: 0 0 150px;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  text-align: center;

  ${media.medium`
    margin: 6vw 0 6vw;
  `}
`

const AnswerTitle = styled.h3`
  ${fontstack.default}
  font-weight: 400;
  margin: 0 0 6vw;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  width: 60%;
  flex: 1;
`

const InnerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  width: 100%;

  ${media.medium`
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 20px;
  `}
`

const ResultsGrid = styled(InnerGrid)`
`

const ExampleInnerGrid = styled(InnerGrid)`
  margin: 50px 0 0;
  border-top: 1px solid white;
` 

const InnerGridColFull = styled.div`
  grid-column: 1 / span 4;
  display: flex;
  justify-content: space-between;

  ${media.medium`
    grid-column: 2 / span 8;
  `}
`

const InnerGridColFirst = styled.div`
  grid-column: 1 / span 4;
  border-top: 1.5px solid white;
  padding: 18px 0 0;

  ${media.medium`
    grid-column: 2 / span 4;
    padding: 0;
    border-top: none;
  `}
`

const InnerGridColLast = styled.div`
  grid-column: 1 / span 4;
  margin: 46px 0 0;

  ${media.medium`
    margin: 0;
    grid-column: 6 / span 4;
  `}
`

const InfoHeader = styled.h5`
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  margin: 0 0 2vw;
`

const DetailsInfoHeader = styled(InfoHeader)`
  display: none;
  ${media.medium`
    display: block;
  `}
`

const InfoText = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  margin: 0;
`

const ArrowCustom = styled(ArrowIcon)`
  ${media.medium`
    display: none;
  `}
`

const ExampleCaseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1.5px solid white;
  padding: 0 0 10px;
  margin: 0 0 10px;

  ${media.medium`
    display: block;
    border-bottom: none;
    padding: 0;
    margin: 0;
  `}
`

const ExampleLabel = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-sm);
  font-weight: 400;
  color: white;
  margin: 25px 0 0;
  text-transform: uppercase;
`

const ResultsEmailWrapper = styled.div`
  border-top: 1.5px solid white;
  grid-column: 1 / span 4;
  margin: 50px 0 0;
  padding: 90px 0 0;

  ${media.medium`
    margin: 2.5vw 0 0;
    padding: 5vw 0 0;
    grid-column: 2 / span 8;
  `}
`

const ResultsEmailTitle = styled.h3`
  ${fontstack.default}
  font-weight: 400;
  margin: 0 0 6vw;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  background: linear-gradient(180deg, #1586D9 0%, #00C965 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  display: none;

  ${media.medium`
    display: block;
  `}
`

const MobileResultsEmailTitle = styled(ResultsEmailTitle)`
  display: block;
  margin: 0 0 50px;

  ${media.medium`
    display: none;
  `}
`

const ExploreResults = ({nodes, selectOne, selectTwo}) => {
  const [ CurrentIndex, setCurrentIndex ] = React.useState(0);

  const nextResult = () => {

    if(CurrentIndex + 1 === nodes.length) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(CurrentIndex+1);
    }
  }
  return (
    <Outer>
      <Wrapper small>
      <div>
        <Title>Your results ({nodes.length}):</Title>
        <Grid>
          <MainCol>
            { nodes && nodes.length > 0 && nodes[CurrentIndex] &&
            <ResultsGrid key={nodes[CurrentIndex].caseProject.slug.current}>
              <InnerGridColFull>
                <AnswerTitle>{nodes[CurrentIndex].title}</AnswerTitle>
                <OutcomeControls onClick={nextResult}>{CurrentIndex + 1}/{nodes.length}</OutcomeControls>
              </InnerGridColFull>
              <InnerGridColFirst>
                <DetailsInfoHeader>
                  Details:
                </DetailsInfoHeader>
                <InfoText><BlockContent blocks={nodes[CurrentIndex]._rawResponse} /></InfoText>
              </InnerGridColFirst>
              <InnerGridColLast>
                <ExampleCaseWrapper>
                  <InfoHeader>
                    Example Case Study:
                  </InfoHeader>
                  <ArrowCustom />
                </ExampleCaseWrapper>
                <InfoText>
                  <Link to={`/project/${nodes[CurrentIndex].caseProject.slug.current}`}>
                    <WorkVideoSummary node={nodes[CurrentIndex].caseProject} hasButton square/> 
                  </Link>
                </InfoText>
              </InnerGridColLast>
            </ResultsGrid>}
            
            <InnerGrid>
              <ResultsEmailWrapper>
                <MobileResultsEmailTitle>Just drop us an email, to find out more.</MobileResultsEmailTitle>
                <ResultsEmailTitle>Just drop us an email,<br/>to find out more.</ResultsEmailTitle>
                <NewsletterMini optionOne={selectOne} optionTwo={selectTwo}/>
              </ResultsEmailWrapper>
            </InnerGrid>
          </MainCol>
        </Grid>
      </div>
    </Wrapper>
    </Outer>
  )

}

export default ExploreResults;
