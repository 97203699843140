import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import { PadSection } from "components/Section/Section";
import { gsap } from "gsap";
import { Draggable, InertiaPlugin } from 'gsap/all';
import DraggableSelectBox from "./DraggableSelectBox";
import { fontstack } from "utils/fontstack";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Box = styled.div`
  position: relative;
  width: 200px;
  height: 70px;
  background: white;
`

const DraggableSelect = ({options, boxClass, selectedHandler, col}) => {
  const { width } = React.useContext(SiteContext);
  const WrapperEl = React.useRef();
  const [Selected, setSelected] = React.useState([]);
  const init = React.useRef();

  const positionsLarge = col === 2 ? [
    { x: -175, y: -85},
    { x: 175, y: -85},
    { x: -175, y: 85},
    { x: 175, y: 85},
  ] :[
    { x: -350, y: -170},
    { x: 0, y: -170},
    { x: 350, y: -170},
    { x: -350, y: 0},
    { x: 0, y: 0},
    { x: 350, y: 0},
    { x: -350, y: 170},
    { x: 0, y: 170},
    { x: 350, y: 170},
    { x: 0, y: 0},
    { x: 0, y: 0},
    { x: 0, y: 0}
  ]

  const positionsMedium = col === 2 ? [
    { x: -160, y: -75},
    { x: 160, y: -75},
    { x: -160, y: 75},
    { x: 160, y: 75},
  ] :[
    { x: -320, y: -150},
    { x: 0, y: -150},
    { x: 320, y: -150},
    { x: -320, y: 0},
    { x: 0, y: 0},
    { x: 320, y: 0},
    { x: -320, y: 150},
    { x: 0, y: 150},
    { x: 320, y: 150},
    { x: 0, y: 0},
    { x: 0, y: 0},
    { x: 0, y: 0}
  ]

  const positionsSmall = col === 2 ? [
    { x: -135, y: -65},
    { x: 135, y: -65},
    { x: -135, y: 65},
    { x: 135, y: 65},
  ] :[
    { x: -270, y: -130},
    { x: 0, y: -130},
    { x: 270, y: -130},
    { x: -270, y: 0},
    { x: 0, y: 0},
    { x: 270, y: 0},
    { x: -270, y: 130},
    { x: 0, y: 130},
    { x: 270, y: 130},
    { x: 0, y: 0},
    { x: 0, y: 0},
    { x: 0, y: 0}
  ]

  const positionsTiny = col === 2 ? [
    { x: -120, y: -65},
    { x: 120, y: -65},
    { x: -120, y: 65},
    { x: 120, y: 65},
  ] :[
    { x: -240, y: -130},
    { x: 0, y: -130},
    { x: 240, y: -130},
    { x: -240, y: 0},
    { x: 0, y: 0},
    { x: 240, y: 0},
    { x: -240, y: 130},
    { x: 0, y: 130},
    { x: 240, y: 130},
    { x: 0, y: 0},
    { x: 0, y: 0},
    { x: 0, y: 0}
  ]

  let positions;

  React.useEffect(() => {
   
    let dragInstance;

    if(!init.current) {
      gsap.registerPlugin(Draggable, InertiaPlugin);
      init.current = true;
    }

    if(width) {
      const elWidth = WrapperEl.current.offsetWidth;
      const elHeight = WrapperEl.current.offsetHeight;
      /*const randomX = gsap.utils.random(150, elWidth - 150, 1, true);
      const randomY = gsap.utils.random(150, elHeight - 150, 1, true);*/
      const boxes = gsap.utils.toArray(`.draggable-${boxClass}`);

      if(width > 1600) {
        positions = positionsLarge;
      } else if (width > 1400) {
        positions = positionsMedium;
      } else if (width > 1100) {
        positions = positionsSmall;
      } else {
        positions = positionsTiny;
        console.log("what");
      }
      boxes.forEach((box,i) => {
        gsap.set(box, { x: (elWidth / 2) + positions[i].x, y: (elHeight / 2) + positions[i].y });
      })
  
      dragInstance = Draggable.create(`.draggable-${boxClass}`,{
        bounds: WrapperEl.current,
        edgeResistance: 0.5,
        type: "x,y",
        inertia: true,
        autoScroll: true,
        });
    }
   


    return () => {
      if(dragInstance) {
        dragInstance.forEach((draggable) => {
          draggable.kill()
        })
      }
    }

  }, [width]);

  const onBoxClick = (index,value) => {

    if(Selected.includes(value)) {
      const arrayIndex = Selected.indexOf(value);
      let newArray = [...Selected];
      newArray.splice(arrayIndex,1);
      setSelected(newArray);

      if(selectedHandler) {
        selectedHandler(newArray);
      }
    } else {
      setSelected(oldArray => [value]);
      if(selectedHandler) {
        selectedHandler(oldArray => [value]);
      }
    }
  }

  return (
    <Wrapper ref={WrapperEl}>
      { options && options.map((option,i) => {
        return <DraggableSelectBox key={`selectbox-${i}`} active={Selected.includes(option)} onClick={() => onBoxClick(i, option)} className={`draggable-${boxClass}`}>{option}</DraggableSelectBox>
      })}
    </Wrapper>
  )
}

export default DraggableSelect;
