import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import NumberedSubtitle from "components/NumberedSubtitle/NumberedSubtitle";
import { PadSection, SectionMargin } from "components/Section/Section";
import { fontstack } from "utils/fontstack";

const Wrapper = styled.div`
  padding: 0;
`
const TitleBox = styled.div`
  border-bottom: 1.5px solid white;
`

const Title = styled.h5`
  ${fontstack.default}
  font-weight: 400;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  padding: 30px 0;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid white; 
`

const Description = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-base);
  color: white;
  padding: 30px 0;
  text-align: center;
`

const Col = styled.div`
  grid-column: 2 / span 10;
`

const ExploreChoiceHeader = ({title,desc,number}) => {

  return (  
    <Wrapper>
      <TitleBox>
        <Title>{number}. {title}</Title>
      </TitleBox>
      <Description>{desc}</Description>
    </Wrapper>
  )
}

export default ExploreChoiceHeader;
