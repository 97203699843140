import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { PadSection } from "components/Section/Section";
import { fontstack } from "utils/fontstack";

const Wrapper = styled(PadSection)`
  ${fontstack.default}
  position: relative;
  color: #807F7F;
  width: 100%;
  font-size: var(--font-size-sm);
  text-transform: uppercase;
  margin: 0 0 14px;
`;

const Number = styled.div`
  grid-column: 2 / span 2;
`;

const Subtitle = styled.div` 
  grid-column: 4 / span 5;
`;

const NumberedSubtitle = ({ children, number }) => {
  return (
    <Wrapper>
      <Grid>
        <Number>{number}</Number>
        <Subtitle>{children}</Subtitle>
      </Grid>
    </Wrapper>
  ) 
}

export default NumberedSubtitle;