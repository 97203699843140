import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import NumberedSubtitle from "components/NumberedSubtitle/NumberedSubtitle";
import { PadSection, SectionMargin } from "components/Section/Section";
import { fontstack } from "utils/fontstack";

const Wrapper = styled.div`
  padding-top: 20vh;
`

const Box = styled.div`
  border: 1.5px solid white;
  border-radius: 5px;
  box-sizing: border-box;
`
const TitleBox = styled.div`
  border: 1px solid white;
  border-radius: 5px;
`

const Title = styled.h5`
  ${fontstack.default}
  font-weight: 300;
  font-size: var(--font-size-md);
  line-height: 1.08;
  color: white;
  padding: 30px 0;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid white; 
`

const Description = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-md-small);
  color: white;
  padding: 30px 0;
  text-align: center;
`

const Col = styled.div`
  grid-column: 2 / span 10;
`

const ExploreChoiceWrapper = ({children}) => {

  return ( 
    <Wrapper>
      <PadSection>
        <Grid>
          <Col>
            <Box>
              {children}
            </Box>
          </Col>
        </Grid>
      </PadSection>
    </Wrapper> 
  )
}

export default ExploreChoiceWrapper;
