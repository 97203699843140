import React from "react";
import styled from "styled-components";
import ArrowSvg from "./assets/arrow.svg";
import { fontstack } from "utils/fontstack";

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border .2s ease;

  & svg {
    stroke: ${(props) => props.active ? "white" : "#807F7F"};
    transition: stroke .2s ease;
  }

`;


const ArrowRectangle = ({active}) => {
  return (
    <Wrapper active={active}>
      <ArrowSvg/>
    </Wrapper>
  ) 
}

export default ArrowRectangle;