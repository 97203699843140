import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { gsap } from "gsap";
import NumberedSubtitle from "components/NumberedSubtitle/NumberedSubtitle";
import { PadSection, SectionMargin } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import BackgroundGradient from "modules/DraggableSelect/assets/gradient-background.jpg";
import ArrowIcon from "./assets/arrow.svg";

const Wrapper = styled.div`
  padding: 0;
  border: 1.5px solid white;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 60px 0;
`
const TitleBox = styled.div`
  border-bottom: 1.5px solid white;
  box-sizing: border-box;
  padding: 0 20px;
`

const Title = styled.h5`
  ${fontstack.default}
  font-weight: 400;
  font-size: var(--font-size-md-plus);
  line-height: 1.08;
  color: white;
  padding: 30px 0;
  margin: 0;
  text-align: center;
`

const Description = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-base);
  color: white;
  padding: 30px 0;
  text-align: center;
`

const Col = styled.div`
  grid-column: span 3;
`
const SelectBox = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 43%;
`

const SelectBoxInner = styled.div`
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  background: url(${BackgroundGradient}) no-repeat ${(props) => props.x && props.y ? `${props.x}% ${props.y}%` : `center center`};
  background-size: 1500px;
  border: 1px solid ${(props) => props.active ? "white" : "transparent"};
  width: 100%;
  height: 100%;
  padding: 1vw;
  box-sizing: border-box;
  box-shadow: ${(props) => props.active ? "0px 0px 8px #FFFFFF" : "none"};
  will-change: box-shadow;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 85px 0;
`

const ExploreChoiceMobile = ({title,desc,number,options, selectedHandler}) => {
  const [Selected, setSelected] = React.useState([]);
  const random = gsap.utils.random(0, 100, null, true);

  const onBoxClick = (index,value) => {

    if(Selected.includes(value)) {
      const arrayIndex = Selected.indexOf(value);
      let newArray = [...Selected];
      newArray.splice(arrayIndex,1);
      setSelected(newArray);

      if(selectedHandler) {
        selectedHandler(newArray);
      }
    } else {
      setSelected(oldArray => [value]);
      if(selectedHandler) {
        selectedHandler(oldArray => [value]);
      }
    }
  }

  return (  
    <PadSection>
      <Wrapper>
        <TitleBox>
          <Title>{number}. {title}</Title>
        </TitleBox>
        <Description>{desc}</Description>
      </Wrapper>
      <Grid>
        { options && options.map((option,i) => {
          return <Col key={i}>
            <MobileSelectBox onClick={() => onBoxClick(i,option)} active={Selected.includes(option)}>{option}</MobileSelectBox>
          </Col>
        })}
      </Grid>
      <ArrowWrapper>
        <ArrowIcon />
      </ArrowWrapper>
    </PadSection>
  )
}

export default ExploreChoiceMobile;


const MobileSelectBox = ({children, active, ...rest}) => {
  const [ X, setX ] = React.useState();
  const [ Y, setY ] = React.useState();

  React.useEffect(() => {
    setX(gsap.utils.random(0, 100, null, true)());
    setY(gsap.utils.random(0, 100, null, true)());
  }, []);

  return (
    <SelectBox {...rest}>
      <SelectBoxInner active={active} x={X} y={Y}>
        {children}
      </SelectBoxInner>
    </SelectBox>
  )
} 


