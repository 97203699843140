import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { SiteContext } from "context/site-context";
import GlowButton from "components/GlowButton/GlowButton";
import { PadSection } from "components/Section/Section";
import { gsap } from "gsap";
import { SplitText, ScrollTrigger } from "gsap/all";
import { fontstack } from "utils/fontstack";
import { media } from "utils/mediaQuery";

const Wrapper = styled(PadSection)`
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
`;


const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${media.medium` 
    display: block;
  `}
`;

const TopGrid = styled(Grid)`
  flex: 1;
  display: flex;
  align-items: center;

  ${media.medium` 
    display: block;
    margin: 10vw 0 0;
  `}
`;

const Col = styled.div`
  grid-column: 1 / span 6;

  ${media.medium` 
    grid-column: 2 / span 9;
  `}
`

const BigStatement = styled.h2`

`

const LineWrapper = styled.div`
  overflow: hidden;
`

const LineText = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-lg);
  line-height: 1;
  font-weight: 400;
  margin: 0;
  color: white;
`

const StatementText = styled(LineText)`
  background: linear-gradient(180deg, #D880FF 0%, #F00F0F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${media.medium` 
    padding: 0 0 20px;
  `}
`

const MobileStatementText = styled(StatementText)`
  display: block;
  ${media.medium` 
    display: none;
  `}
`

const DesktopStatementText = styled(StatementText)`
  display: none;
  ${media.medium` 
    display: block;
  `}
`

const BottomStatementGrid = styled(Grid)` 
  position: relative;
  bottom: 0;
  left: 0;
  
  ${media.medium` 
    position: relative;
    margin: 120px 0 0;
  `}
`;

const BottomStatement = styled.p`
  margin: 0;
  grid-column: 1 / span 6;
  ${fontstack.default}
  font-size: var(--font-size-base);
  font-weight: 400;
  color: white;
  border-top: 1px solid #fff;
  padding: 1.8vw 0 0;

  ${media.medium` 
    grid-column: 9 / span 3;
  `}
`;

const ExploreIntro = () => {

  React.useEffect(() => {

      const tl = gsap.timeline({
        onComplete: () => {
          ScrollTrigger.refresh();
        }
      });
   
      tl.from(".explore-intro-line", { 
        opacity:0,
        y: "-100%",
        duration: 1,
        ease: "back.inOut"
      })
      
  }, [])

  return (
    <Wrapper id="explore-intro-wrapper">
      <InnerWrapper>
        <TopGrid>
          <Col>
            <BigStatement>
              <LineText>Explore</LineText>
              <LineWrapper>
                <MobileStatementText className="explore-intro-line">how you can achieve what you need.</MobileStatementText>
                <DesktopStatementText className="explore-intro-line">how you can achieve<br/>what you need.</DesktopStatementText>
              </LineWrapper>
            </BigStatement>
          </Col>
        </TopGrid>
        <BottomStatementGrid>
          <BottomStatement>
          An interactive form whereby answering two questions can formulate a series of answers
that pertain to your professional needs.
          </BottomStatement>
        </BottomStatementGrid>
      </InnerWrapper>
    </Wrapper>
  )
}

export default ExploreIntro;
