import React from "react";
import styled from "styled-components";
import { fontstack } from "utils/fontstack";
import { media } from "utils/mediaQuery";
import ArrowSvg from "./assets/arrow.svg";

const Wrapper = styled.div`
  border: 1px solid white;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  width: 50px;
  height: 70px;
  overflow: hidden;

  ${media.medium`
    width: 58px;
    height: 82px;
  `}
`;

const Counter = styled.div`
   ${fontstack.default}
    position: relative;
    color: var(--brand-black);
    font-size: 17px;
    line-height: 20px;
    background: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const ArrowWrapper = styled.div`
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const OutcomeControls = ({children, ...rest}) => {

  return (
    <Wrapper {...rest}>
      <Counter>
        { children } 
      </Counter>
      <ArrowWrapper>
        <ArrowSvg />
      </ArrowWrapper>
    </Wrapper>
  )
}

export default OutcomeControls;
