import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import styled from "styled-components";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import ExploreIntro from "modules/ExploreIntro/ExploreIntro";
import ExploreChoiceMobile from "modules/ExploreChoiceMobile/ExploreChoiceMobile";
import ExploreChoiceWrapper from "modules/ExploreChoiceWrapper/ExploreChoiceWrapper";
import ExploreChoiceHeader from "modules/ExploreChoiceHeader/ExploreChoiceHeader";
import ExploreResults from "modules/ExploreResults/ExploreResults";
import DraggableSelect from "modules/DraggableSelect/DraggableSelect";
import { media } from "utils/mediaQuery";

export const query = graphql`
  query ExplorePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    results: allSanityResult {
      edges {
        node {
          title
          _rawResponse
          caseProject {
            thumbnail {
              mobileVideo
              video
            }
            title
            subtitle
            slug {
              current
            }
          }
          audience {
            label
            value
          }
          organization {
            label
            value
          }
        }
      }
    }
  }
`;

const DragStage = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  min-height: 600px;
`

const MobileChoices = styled.div`
  display: block;
  ${media.medium` 
    display: none;
  `}
`;

const DesktopChoices = styled.div`
  display: none;
  ${media.medium` 
    display: block;
  `}
`;

const ExplorePage = props => {
  const { data, errors } = props;
  const [ DragSelectedOne, setDragSelectedOne ] = React.useState([]);
  const [ DragSelectedTwo, setDragSelectedTwo ] = React.useState([]);
  const [ Results, setResults ] = React.useState([]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const results = (data || {}).results;
  const resultsNodes = mapEdgesToNodes(results);

  React.useEffect(() => {
    const filteredResults = resultsNodes.filter((result) => {
      const organization = result.organization;
      const audience = result.audience;
      return organization.filter((obj,i) => DragSelectedOne.includes(obj.value)).length > 0 && audience.filter((obj,i) => DragSelectedTwo.includes(obj.value)).length > 0;
    })

  
    setResults(filteredResults);

  }, [DragSelectedOne,DragSelectedTwo])


  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout nofooter activeItem={"explore"}>
      <SEO title="Explore" description="An interactive form whereby answering two questions can formulate a series of answers that pertain to your professional needs." />
      <ExploreIntro />
      <MobileChoices>
        <ExploreChoiceMobile  
          number="1" 
          title="Who are you?" desc={<>Select 1 answer.<br/>You have selected ({DragSelectedOne.length})</>}
          options={[
            "Technology",
            "Government",
            "Finance",
            "Manufacturing",
            "Blockchain",
            "Arts & Culture",
            "Science",
            "NGO",
            "Professional Services"
          ]}
          selectedHandler={setDragSelectedOne}
        />
        <ExploreChoiceMobile  
          number="2" 
          title="Who are your audience(s)?" desc={<>Select 1 answer.<br/>You have selected ({DragSelectedTwo.length})</>}
          options={[
            "Investors",
            "Consumers",
            "Business",
            "Governments",
          ]}
          selectedHandler={setDragSelectedTwo}
        />
      </MobileChoices>
      <DesktopChoices>
        <ExploreChoiceWrapper>
          <ExploreChoiceHeader number="1" title="Who are you?" desc={`Select 1 answer. You have selected (${DragSelectedOne.length})`}/>
          <DragStage>
            <DraggableSelect selectedHandler={setDragSelectedOne} boxClass="box-one" options={[
              "Technology",
              "Government",
              "Finance",
              "Manufacturing",
              "Blockchain",
              "Arts & Culture",
              "Science",
              "NGO",
              "Professional Services"
            ]}/>
          </DragStage>
        </ExploreChoiceWrapper>
        <ExploreChoiceWrapper>
          <ExploreChoiceHeader number="2" title="Who are your audience(s)?" desc={`Select 1 answer. You have selected (${DragSelectedTwo.length})`}/>
          <DragStage>
            <DraggableSelect selectedHandler={setDragSelectedTwo} boxClass="box-two" col={2} options={[
              "Investors",
              "Consumers",
              "Business",
              "Governments"
            ]}/>
          </DragStage>
        </ExploreChoiceWrapper>
      </DesktopChoices>
      <ExploreResults nodes={Results} selectOne={DragSelectedOne} selectTwo={DragSelectedTwo}/>
    </Layout>
  );
};

export default ExplorePage;

