import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { fontstack } from "utils/fontstack";
import { media } from "utils/mediaQuery";
import { gsap } from "gsap";


const Wrapper = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-base);
  line-height: 1.18;
  font-weight: 400;
  color: white;
  border-radius: 2px;
  border: 1px solid white;
  padding: 6px 6px;
  display: inline-block;
  white-space: pre;

  ${media.medium` 
    padding: 2px 6px;
  `}
`

const VideoTag = ({children}) => {

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default VideoTag;
