import React from "react";
import styled from "styled-components";
import Grid from "components/Grid/Grid";
import { PadSection } from "components/Section/Section";
import { fontstack } from "utils/fontstack";
import { gsap } from "gsap";
import BackgroundGradient from "./assets/gradient-background.jpg";


const Wrapper = styled.div`
  position: absolute;
  width: 19vw;
  max-width: 320px;
`;

const Box = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 43%;
  transform: translate(-50%, -50%);
`

const Inner = styled.div`
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  background: url(${BackgroundGradient}) no-repeat ${(props) => props.x && props.y ? `${props.x}% ${props.y}%` : `center center`};
  background-size: 1500px;
  border: 1px solid ${(props) => props.active ? "white" : "transparent"};
  width: 100%;
  height: 100%;
  padding: 1vw;
  box-sizing: border-box;
  box-shadow: ${(props) => props.active ? "0px 0px 30px #FFFFFF" : "none"};
  will-change: box-shadow;
`;

const Label = styled.div`
  ${fontstack.default}
  font-size: var(--font-size-md-small);
  font-weight: 400;
  color: white;
  box-sizing: border-box;
  pointer-events: none;
`

const DraggableSelectBox = ({children, active, className, ...rest}) => {
  const [ X, setX ] = React.useState();
  const [ Y, setY ] = React.useState();

  React.useEffect(() => {
    setX(gsap.utils.random(0, 100, null, true)());
    setY(gsap.utils.random(0, 100, null, true)());
  }, []);

  

  return (
    <Wrapper {...rest}>
      <Box className={className}>
        <Inner active={active} x={X} y={Y} >
          <Label>{children}</Label>
        </Inner>
      </Box>
    </Wrapper>
  )
}

export default DraggableSelectBox;